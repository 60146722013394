"use client";

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { useRouter } from "next/navigation";

interface DialogSearchContextType {
  isSearchOpen: boolean;
  openSearch: (autoFocus?: boolean) => void;
  closeSearch: () => void;
  shouldAutoFocus: boolean;
}

const DialogSearchContext = createContext<DialogSearchContextType | undefined>(
  undefined
);

export function DialogSearchProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);

  const openSearch = useCallback((autoFocus: boolean = false) => {
    console.log("openSearch called, setting isSearchOpen to true");
    setIsSearchOpen(true);
    setShouldAutoFocus(autoFocus);
  }, []);

  const closeSearch = useCallback(() => {
    console.log("closeSearch called, setting isSearchOpen to false");
    setIsSearchOpen(false);
    setShouldAutoFocus(false);
  }, []);

  console.log("DialogSearchProvider rendering, isSearchOpen:", isSearchOpen);

  return (
    <DialogSearchContext.Provider
      value={{ isSearchOpen, openSearch, closeSearch, shouldAutoFocus }}
    >
      {children}
    </DialogSearchContext.Provider>
  );
}

export function useDialogSearch() {
  const context = useContext(DialogSearchContext);
  if (!context) {
    throw new Error(
      "useDialogSearch must be used within a DialogSearchProvider"
    );
  }
  return context;
}
