"use client";

import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";

interface DialogWaitingListContextType {
  isWaitingListOpen: boolean;
  openWaitingList: () => void;
  closeWaitingList: () => void;
}

const DialogWaitingListContext = createContext<
  DialogWaitingListContextType | undefined
>(undefined);

export function DialogWaitingListProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [isWaitingListOpen, setIsWaitingListOpen] = useState(false);

  const openWaitingList = useCallback(() => {
    console.log("openWaitingList called, setting isWaitingListOpen to true");
    setIsWaitingListOpen(true);
  }, []);

  const closeWaitingList = useCallback(() => {
    console.log("closeWaitingList called, setting isWaitingListOpen to false");
    setIsWaitingListOpen(false);
  }, []);

  console.log(
    "DialogWaitingListProvider rendering, isWaitingListOpen:",
    isWaitingListOpen
  );

  return (
    <DialogWaitingListContext.Provider
      value={{ isWaitingListOpen, openWaitingList, closeWaitingList }}
    >
      {children}
    </DialogWaitingListContext.Provider>
  );
}

export function useDialogWaitingList() {
  const context = useContext(DialogWaitingListContext);
  if (!context) {
    throw new Error(
      "useDialogWaitingList must be used within a DialogWaitingListProvider"
    );
  }
  return context;
}
