// RootProvider.tsx
"use client";

import React from "react";
import { ChatProvider } from "@/context/ChatContext";
import { DialogSearchProvider } from "@/context/DialogSearchContext";
import { DialogWaitingListProvider } from "@/context/DialogWaitingListContext";
import { SessionProvider } from "next-auth/react";
import { PublisherProvider } from "@/context/PublisherContext";

function RootProvider({ children }: { children: React.ReactNode }) {
  return (
    <PublisherProvider>
      <ChatProvider>
        <DialogSearchProvider>
          <DialogWaitingListProvider>
            <SessionProvider refetchInterval={4 * 60}>
              {children}
            </SessionProvider>
          </DialogWaitingListProvider>
        </DialogSearchProvider>
      </ChatProvider>
    </PublisherProvider>
  );
}

export default RootProvider;
