import(/* webpackMode: "eager" */ "/appbuild/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/appbuild/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/appbuild/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/fonts/Gilroy-ExtraBold.otf\",\"variable\":\"--font-gilroy\",\"display\":\"swap\"}],\"variableName\":\"gilroy\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/appbuild/src/app/RootProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/appbuild/src/components/SearchDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/appbuild/src/components/SessionGuard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/appbuild/src/components/ui/toaster.tsx");
