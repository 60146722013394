"use client";

import { useDialogSearch } from "@/context/DialogSearchContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from "@/components/ui/dialog";
import { SearchCore } from "./SearchCore";

export default function SearchDialog() {
  const { isSearchOpen, closeSearch } = useDialogSearch();

  return (
    <Dialog open={isSearchOpen} onOpenChange={closeSearch}>
      <DialogContent className="fixed left-1/2 top-[280px] -translate-x-1/2 w-[807px] h-[258px] bg-[#f3f3f3] shadow-lg overflow-hidden !max-w-[807px] sm:!max-w-[807px]">
        <DialogHeader>
          <DialogTitle></DialogTitle>
          <DialogDescription className="sr-only">
            Search dialog for asking questions
          </DialogDescription>
        </DialogHeader>
        <div className="relative z-10 h-full flex items-center justify-center">
          <SearchCore onSearchComplete={closeSearch} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
