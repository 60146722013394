"use client";

import { signIn, useSession } from "next-auth/react";
import { ReactNode, useEffect } from "react";
import { useRouter } from "next/navigation";
import { useToast } from "@/hooks/use-toast";
import Login from "./Login";

export default function SessionGuard({ children }: { children: ReactNode }) {
  const { data: session, status } = useSession();
  const router = useRouter();
  const { toast } = useToast();

  useEffect(() => {
    if (session?.error) {
      toast({
        title: "Session Error",
        description: "Please sign in again to continue.",
        variant: "destructive",
      });

      // Preserve current path for redirect back after auth
      const currentPath = window.location.pathname + window.location.search;
      const signInUrl = new URL("/auth/signin", window.location.origin);
      signInUrl.searchParams.set("callbackUrl", currentPath);

      router.push(signInUrl.toString());
    }
  }, [session, router, toast]);

  if (status === "loading") {
    return null; // Or a loading spinner
  }

  if (status === "unauthenticated" || session?.error) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <Login />
      </div>
    );
  }

  return <>{children}</>;
}
